/** @format */

import axios from "axios";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import CrmCard from "./CrmCard/CrmCard";
import "./CrmIntigration.css";
import CrmIntigrationHeader from "./CrmIntigrationHeader/CrmIntigrationHeader";
import Boomtown from "./Images/boomtown.png";
import FreshSalse from "./Images/freshsales.png";
import Fub from "./Images/FUB.png";
import HubSpot from "./Images/hubspot.png";
import Kvcore from "./Images/kvcore.png";
import MarketLeader from "./Images/MarketLeader.png";
import Pipedrive from "./Images/Pipedrive.png";
import RealGeeks from "./Images/RealGeeks.png";
import SalsesForce from "./Images/Salesforce.png";
import Zoho from "./Images/zoho.png";
import GoHighLevel from "./Images/GoHighLevel.png";

export default function CrmIntigration() {
  const params = useParams();

  return (
    <>
      {params.crm === undefined ? (
        <div className="Container">
          <CrmIntigrationHeader />
          <div className="crm_Intigration_container">
            {/* <CrmCard crmimage={Boomtown} crmpath={"boomtown"} /> */}
            {/* <CrmCard crmimage={FreshSalse} crmpath={"freshsales"} /> */}
            {/* <CrmCard crmimage={Fub} crmpath={"fub"} /> */}
            <CrmCard crmimage={GoHighLevel} crmpath={"GoHgihLevelCrm"} />
            {/* <CrmCard crmimage={HubSpot} crmpath={"hubspot"} /> */}
            {/* <CrmCard crmimage={Kvcore} crmpath={"kvcore"} /> */}
            {/* <CrmCard crmimage={MarketLeader} crmpath={"marketleader"} /> */}
            {/* <CrmCard crmimage={Pipedrive} crmpath={"pipedrive"} /> */}
            {/* <CrmCard crmimage={RealGeeks} crmpath={"realgeeks"} /> */}
            {/* <CrmCard crmimage={SalsesForce} crmpath={"salseforce"} /> */}
            {/* <CrmCard crmimage={Zoho} crmpath={"zoho"} /> */}
          </div>
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
}
