import { Route, Routes } from "react-router-dom";

//context
import DashboardBlogsContextProvider from "../context/DashboardBlogs.context";
import DashboardExclusiveContextProvider from "../context/DashboardExclusiveListing";
import DashboardMlsContextProvider from "../context/DashboardMlsListing.context";
import Blogs from "../pages/DashBoard/Components/Blogs/Blogs";
import BuyerDetail from "../pages/DashBoard/Components/BuyersLeads/BuyerDetail";
import BuyersForm from "../pages/DashBoard/Components/BuyersLeads/BuyersLeads";
import CrmIntigration from "../pages/DashBoard/Components/CrmIntigration/CrmIntigration";
import CrmListing from "../pages/DashBoard/Components/CrmIntigration/CrmListting/MlsListing";
import Crmsetting from "../pages/DashBoard/Components/CrmIntigration/crmsetting/crmsetting";
import DomainLinking from "../pages/DashBoard/Components/DomainLinking/DomainLinking";
import ExclusiveListings from "../pages/DashBoard/Components/ExclusiveListing/ExclusiveListings";
import LeadCount from "../pages/DashBoard/Components/LeadCount/LeadCount";
import OtherLead from "../pages/DashBoard/Components/Other Leads/OtherLead";
import PricingBiling from "../pages/DashBoard/Components/Pricing&Biling/PricingBiling";
import { ProfileDashboard } from "../pages/DashBoard/Components/profile/profile.dashboard";
import SellerDetail from "../pages/DashBoard/Components/SellersLeades/SellerDetail";
import SellerForm from "../pages/DashBoard/Components/SellersLeades/SellerLeaders";
import Seomanager from "../pages/DashBoard/Components/Seomanager/Seomanager";
import ShareProperty from "../pages/DashBoard/Components/shareProperty/shareProperty.dashboardcomponent";
import { TemplateSwitchingDashboard } from "../pages/DashBoard/Components/TemplateSwitching/templateswitching.dashboard";
import DashBoard from "../pages/DashBoard/DashBoard";
import OtherDetail from "../pages/DashBoard/Components/Other Leads/OtherDetail";
import CrmContextProvider from "../context/crm.context";

export default function DashBoardRoute() {
  return (
    <Routes>
      <Route path="/" element={<DashBoard />}>
        <Route
          path="blogs"
          element={
            <DashboardBlogsContextProvider>
              <Blogs />
            </DashboardBlogsContextProvider>
          }
        />

        <Route path="pricing-blilling" element={<PricingBiling />} />
        <Route path="buyers-lead" element={<BuyersForm />} />
        <Route path="other-leads" element={<OtherLead />} />
        <Route path="seller-lead" element={<SellerForm />} />
        <Route
          path="crmintigration"
          element={
            <CrmContextProvider>
              <CrmIntigration />
            </CrmContextProvider>
          }
        >
          <Route path=":crm/settings" element={<Crmsetting />} />
          <Route path=":crm/listings" element={<CrmListing />} />
        </Route>
        <Route
          path="mls_listing"
          element={
            <DashboardMlsContextProvider>
              <CrmListing />
            </DashboardMlsContextProvider>
          }
        />
        <Route path="seomanager" element={<Seomanager />} />
        <Route path="leadcount" element={<LeadCount />} />
        <Route path="domain_linking" element={<DomainLinking />} />
        <Route path="/buyer-detail/:id" element={<BuyerDetail />} />
        <Route path="/seller-detail/:id" element={<SellerDetail />} />
        <Route path="/other-detail/:id" element={<OtherDetail />} />
        <Route
          path="Exclusive_listing"
          element={
            <DashboardExclusiveContextProvider>
              <ExclusiveListings />
            </DashboardExclusiveContextProvider>
          }
        />
        <Route path="profile" element={<ProfileDashboard />} />
        <Route
          path="templateswitching"
          element={<TemplateSwitchingDashboard />}
        />
        <Route
          path="shareProperty"
          element={
            <DashboardMlsContextProvider>
              <ShareProperty />
            </DashboardMlsContextProvider>
          }
        />
      </Route>
    </Routes>
  );
}
