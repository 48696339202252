import axios from "axios";
import {
  createContext,
  useReducer,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import Cookies from "js-cookie";
export type State = {
  crm: null | {
    key: string;
    apiKey: string;
    activated: boolean;
  };
  crmList: null | object;
};

type Action =
  | {
      type: "set_crm";
      payload: {
        value: {
          key: string;
          apiKey: string;
          activated: boolean;
        };
      };
    }
  | { type: "set_crmList"; payload: object }
  | { type: "add_crm_list"; payload: object };

type ContextValue = {
  state: State;
  setCrm: (value: {}) => void;
  setCrmList: (value: {}) => void;
  AddCrmList: (value: {}) => void;
  setApiKey: (crm: string, apiKey: string) => void;
};

const initialState: State = {
  crm: {
    key: "",
    apiKey: "",
    activated: false,
  },
  crmList: null,
};

const CrmReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "set_crm":
      return { ...state, crm: action.payload.value };
    case "set_crmList":
      return { ...state, crmList: action.payload };
    case "add_crm_list":
      return { ...state, crmList: action.payload };
  }
};

const CrmContext = createContext<ContextValue | undefined>(undefined);

type CrmProviderProps = {
  children: ReactNode;
};

const CrmContextProvider = ({ children }: CrmProviderProps) => {
  const [state, dispatch] = useReducer(CrmReducer, initialState);
  useEffect(() => {
    const getUserProfile = async () => {
      const token = Cookies.get("token");
      const profile = await axios.get("/admin/get-user-profile", {
        headers: {
          "x-access-token": token,
        },
      });
      AddCrmList(profile.data.data.crm);
    };
    getUserProfile();
  }, []);

  const updateCrmSettings = async (
    crmKey: string,
    activated: boolean,
    newApiKey: string,
  ) => {
    try {
      const response = await axios.put(
        "/admin/UpdateCrmApiKey",
        {
          crmKey: crmKey,
          activated: activated,
          newApiKey: newApiKey,
        },
        {
          headers: {
            "x-access-token": Cookies.get("token"),
          },
        },
      );
    } catch (e) {
      throw new Error("something went wrong cant add the crm");
    }
  };
  const setCrm = (value: {
    key: string;
    apiKey: string;
    activated: boolean;
  }) => {
    dispatch({ type: "set_crm", payload: { value } });
  };

  const AddCrmList = (value: {}) => {
    dispatch({ type: "add_crm_list", payload: value });
  };
  const setCrmList = (value: string) => {
    const crmUpdated = ToggleCrm(value);
    updateCrmSettings(
      value,
      crmUpdated[value].activated,
      crmUpdated[value].apiKey,
    );
    dispatch({ type: "set_crmList", payload: crmUpdated });
  };

  const setApiKey = (crm: string, apiKey: string): void => {
    const crmUpdated = updateCrmList(crm, apiKey);
    updateCrmSettings(crm, crmUpdated[crm].activated, apiKey);
    dispatch({ type: "set_crmList", payload: crmUpdated });
  };

  const ToggleCrm = (crm: string): Record<string, any> => {
    const newCrmList: Record<string, any> = {};
    if (state.crmList) {
      for (const [key, value] of Object.entries(state.crmList)) {
        if (key === crm) {
          newCrmList[key] = { ...value, activated: !value.activated };
        } else {
          newCrmList[key] = { ...value, activated: false };
        }
      }
    }
    return newCrmList;
  };
  const updateCrmList = (crm: string, apiKey: string) => {
    const newCrmList: Record<string, any> = {};
    if (state.crmList) {
      for (const [key, value] of Object.entries(state.crmList)) {
        if (key === crm) {
          newCrmList[key] = { ...value, apiKey: apiKey };
        }
      }
    }
    return newCrmList;
  };
  const ContextValue: ContextValue = {
    state,
    setCrm,
    setCrmList,
    AddCrmList,
    setApiKey,
  };

  return (
    <CrmContext.Provider value={ContextValue}>{children}</CrmContext.Provider>
  );
};

export default CrmContextProvider;

//custom hook

export function useCrmContext() {
  const context = useContext(CrmContext);
  if (!context) {
    throw new Error("CrmContext must be used within a CrmContextProvider");
  }
  return context;
}
