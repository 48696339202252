/** @format */

import { useEffect, useState } from "react";
import "./crmsetting.css";
import CrmsettingHeader from "./crmsettingHeader";
import { useParams } from "react-router-dom";
import { useCrmContext } from "../../../../../context/crm.context";
export default function Crmsetting() {
  const [crmKey, setCrmKey] = useState("");
  const { state, setCrmList, setApiKey } = useCrmContext();
  const params = useParams();

  const toggleButton = () => {
    if (crmKey.length > 0) {
      setCrmList(params.crm);
    }
  };

  const onCrmKeyChange = (event: any) => {
    setCrmKey(event.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setApiKey(params.crm, crmKey);
  };

  useEffect(() => {
    if (state.crmList) {
      console.log(state?.crmList);
      setCrmKey(state?.crmList[params?.crm].apiKey);
    }
  }, [state]);

  return (
    <div className="Container">
      <CrmsettingHeader />
      <div className="crm_setting_container">
        <div className="crm_setting_header d-flex justify-content-between align-items-center">
          <div className="crm_content d-flex align-items-baseline gap-2">
            <h1>{params.crm}</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur. Sem cursus vestibulum
              mauris feugiat diam eget non. Turpis.
            </p>
          </div>
          <div
            className={`toggle-btn ${state.crmList && state.crmList[params?.crm].activated ? "active" : ""}`}
            onClick={toggleButton}
          >
            <div className="inner-circle"></div>
          </div>
          H
        </div>
        <div className="crm_access_key_info">
          <div className="info_container">
            <h1>Where to find the access key?</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur. Vel mauris elit etiam
              urna. Felis aliquam et mi massa vulputate lectus. Vitae est at
              suscipit euismod aliquet purus. Sed augue enim placerat tempor
              egestas vitae ultricies id amet. Turpis non purus viverra ac
              faucibus placerat nibh lectus. Viverra malesuada lacus dui sapien
              vulputate vitae. Sit nisl commodo velit purus risus sed nibh diam
              odio. Vestibulum neque sit id in odio aliquet tortor. Curabitur
              sapien et ultrices molestie. Proin integer tempor adipiscing
              mollis augue. Aliquet nisl laoreet feugiat scelerisque semper. Ut
              scelerisque velit volutpat feugiat dui. Malesuada pharetra mauris
              volutpat vehicula donec magna volutpat dictum. Lectus habitant
              neque lobortis consectetur risus amet est pulvinar justo. Sed
              imperdiet ut augue fermentum semper dui nisl. Scelerisque sagittis
              et purus ullamcorper. Viverra eu magnis eu et. Gravida enim amet
              nunc in id eget dolor nisl. Mattis ultrices pharetra elementum
              volutpat arcu accumsan .
            </p>
          </div>
        </div>
        <div className="key_case d-flex align-items-center gap-4 mt-3">
          <h1>Access Key</h1>
          <div className="key d-flex align-items-center gap-2 ">
            <div className="key_icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M2.14546 10.8281L1.92421 12.375C1.91054 12.471 1.9194 12.5689 1.95009 12.6609C1.98077 12.7529 2.03245 12.8365 2.10103 12.905C2.1696 12.9736 2.2532 13.0253 2.3452 13.056C2.4372 13.0867 2.53507 13.0955 2.63109 13.0818L4.17796 12.8606C4.41234 12.8275 4.99984 11.25 4.99984 11.25C4.99984 11.25 5.29484 11.5031 5.41546 11.5412C5.67296 11.6225 5.92359 11.37 6.00796 11.1137L6.24984 10.0062C6.24984 10.0062 6.61046 10.1887 6.74109 10.2156C6.90734 10.25 7.06859 10.1475 7.18296 10.0325C7.25167 9.96397 7.30327 9.88025 7.33359 9.7881L7.49984 8.75622C7.49984 8.75622 7.92171 8.8731 8.06609 8.88997C8.23046 8.90872 8.39046 8.82497 8.50796 8.70684L9.21921 7.99622C9.82128 8.19131 10.4655 8.21647 11.0809 8.06893C11.6964 7.92138 12.2592 7.60685 12.7073 7.15997C13.3509 6.51475 13.7123 5.64065 13.7123 4.72934C13.7123 3.81804 13.3509 2.94394 12.7073 2.29872C12.0621 1.65517 11.188 1.29376 10.2767 1.29376C9.36541 1.29376 8.49131 1.65517 7.84609 2.29872C7.39909 2.7468 7.08448 3.30959 6.93692 3.92506C6.78937 4.54053 6.8146 5.1848 7.00984 5.78685L2.32171 10.4743C2.22602 10.5699 2.16414 10.6941 2.14546 10.8281ZM11.5648 3.44122C11.9058 3.7832 12.0973 4.24642 12.0973 4.72934C12.0973 5.21227 11.9058 5.67549 11.5648 6.01747L8.98859 3.44122C9.33057 3.10025 9.79379 2.90877 10.2767 2.90877C10.7596 2.90877 11.2229 3.10025 11.5648 3.44122Z"
                  fill="#FAFAFC"
                />
              </svg>
            </div>
            <input
              className="key_input"
              type="text"
              onChange={onCrmKeyChange}
              value={crmKey}
              placeholder="Enter your access key here"
            />
            <button className="btn btn-light text-dark" onClick={onSubmit}>
              Update the Crm Key
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
